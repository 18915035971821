<template>
  <v-app>
    <v-main>
      <router-view />
      <!-- Időpont foglaló gomb -->
      <div @click="goToIdopontfoglalas()" :class="['idopont-foglalo-btn']">
        <span class="font-weight-light text-uppercase text-caption"
          >Időpont foglalás</span
        >
      </div>
      <!-- Időpont foglaló gomb vége -->
      <!-- Back to Top Button -->
      <div @click="scrollToTop()" :class="['back-to-top-btn', { 'go-top': isTop }]">
        <v-icon>keyboard_arrow_up</v-icon>
      </div>
      <!-- Back to Top Button vége -->
    </v-main>
    <v-footer class="d-flex flex-column" color="#8b6c57">
      <div class="text-center white--text">
        <strong>Shining Body</strong> | Győr |
        <strong>{{ new Date().getFullYear() }}</strong>
      </div>
      <!-- <div class="text-center">
        {{ new Date().getFullYear() }}
      </div> -->
    </v-footer>
    <!-- </v-main> -->
  </v-app>
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  name: "App",
  data: () => ({
    isTop: false,
    group: null,
  }),
  methods: {
    scrollToTop() {
      window.scroll({ behavior: "smooth", left: 0, top: 0 });
    },
    goToIdopontfoglalas() {
      window.location.href = "https://shining-body-alakformalo-studio.salonic.hu/";
    },
  },
  mounted() {
    document.title = "Shining Body Alakformáló Stúdió Győr";
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 120) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@keyframes pulse {
  0% {
    transform: scale(0.9);
    // background-color: rgba(255, 0, 255, 0.6); //pink
    // background-color: #c2a38e; //barna
    // color: rgba(255, 0, 255, 0.8); //pink
    // color: white;
    // box-shadow: 0 0 10px rgba(255, 0, 255, 0.6); //pink
    // box-shadow: 0 0 15px #c2afa3; //barnás szürke
    box-shadow: 0 0 15px rgba(116, 116, 116, 0.8); //barnás szürke
  }
  50% {
    transform: scale(1.05);
    // background-color: rgba(255, 0, 255, 0.8); //pink
    // background-color: #c2a38e; //barna
    // color: rgba(255, 0, 255, 0.8); //pink
    // color: #ffffff;
    // box-shadow: 0 0 20px rgba(255, 0, 255, 0.8); //pink
    box-shadow: 0 0 18px rgb(61, 61, 61); //barnás szürke
  }
  100% {
    transform: scale(0.9);
    // background-color: rgba(255, 0, 255, 0.6); //pink
    // background-color: #c2a38e; //barna
    // color: rgba(255, 0, 255, 0.8); //pink
    // color: #ffffff;
    // box-shadow: 0 0 10px rgba(255, 0, 255, 0.6); //pink
    // box-shadow: 0 0 15px #c2afa3; //barnás szürke
    box-shadow: 0 0 15px rgb(116, 116, 116, 0.8); //barnás szürke
  }
}
div {
  position: relative;
  overflow: hidden;
}
.idopont-foglalo-btn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  // background-color: rgba(240, 78, 240, 0.6); //pink
  background-color: #c2a38e; //barna
  color: white;
  text-align: center;
  border-radius: 50%;
  // border: 0.7px solid rgba(46, 24, 0, 0.267);
  position: fixed;
  top: 45%;
  right: 18px;
  opacity: 0.9;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  animation: pulse 1.5s infinite ease-in-out; /* Pulzálás folyamatosan */
  transition: all 0.3s ease-in-out;

  &:hover {
    // background-color: rgba(255, 0, 255, 0.8);
    background-color: #9b8271;
    transform: scale(1.1);
  }
}

.back-to-top-btn {
  position: fixed;
  cursor: pointer;
  left: 40px;
  color: #ffffff;
  // background-color: #dfa110;
  background-color: #c2a38e;
  width: 40px;
  height: 40px;
  visibility: hidden;
  border-radius: 100%;
  transition: 0.6s;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

  i {
    position: absolute;
    right: 0;
    left: 0;
    top: 45%; //ez állítja az ikon pozícióját
    transform: translateY(-45%);
    font-size: 22px; //ez állítja az ikon méretét
    margin-left: auto;
    margin-right: auto;
  }
  &.go-top {
    opacity: 1;
    visibility: visible;
    bottom: 70px;
  }
  &:hover {
    opacity: 1;
    background-color: #c2a38e;
    color: #ffffff;
    transition: 0.6s;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transform: translateY(-5px);
  }
}
</style>
